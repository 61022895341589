import { forwardRef, useCallback, useState } from 'react'
import classnames from 'classnames'
import Image from 'next/image'

import { GraphicLines, GraphicLinesTwo } from '../../../assets/icons'
import CTAButton from '../../button'
import TalentImage from '../../../assets/backgrounds/imgTalent.png'
import CompanyImage from '../../../assets/backgrounds/imgCompanies.png'

import styles from './index.module.scss'

const ANIMATIONS = {
  TALENT: 'TALENT',
  COMPANY: 'COMPANY',
}

export const HomeAnimatedSection = forwardRef<HTMLElement>((_, ref) => {
  const [currentAnimation, setCurrentAnimation] = useState<string | undefined>(undefined)

  const onTalentAnimation = useCallback(() => {
    setCurrentAnimation(ANIMATIONS.TALENT)
  }, [])

  const onCompanyAnimation = useCallback(() => {
    setCurrentAnimation(ANIMATIONS.COMPANY)
  }, [])

  const onRemoveAnimation = useCallback(() => {
    setCurrentAnimation(undefined)
  }, [])

  return (
    <section
      className={classnames(styles['section-container'], {
        [styles['talent-background']]: currentAnimation === ANIMATIONS.TALENT,
        [styles['company-background']]: currentAnimation === ANIMATIONS.COMPANY,
      })}
      ref={ref}
    >
      <Image
        src={TalentImage}
        className={classnames(styles['background-image'], styles['talent-background-image'], {
          [styles['image-visible']]: currentAnimation === ANIMATIONS.TALENT,
        })}
        alt="Person smiling while working with a team in a desk with personal computers"
        fill
        sizes="100vw"
      />
      <Image
        src={CompanyImage}
        className={classnames(styles['background-image'], {
          [styles['image-visible']]: currentAnimation === ANIMATIONS.COMPANY,
        })}
        alt="Person smiling while closing a deal by handshaking another person"
        fill
        sizes="100vw"
      />
      <div className={styles['content']}>
        <h2 className={styles['title']}>Let&apos;s get there</h2>
        <p className={styles['description']}>Engaging tech talent for trustworthy companies.</p>
        <CTAButton
          href="/for-talent"
          className={classnames(styles['talent-button'], styles['button'])}
          onMouseEnter={onTalentAnimation}
          onFocus={onTalentAnimation}
          onMouseLeave={onRemoveAnimation}
          onBlur={onRemoveAnimation}
          variant={currentAnimation === ANIMATIONS.COMPANY ? 'opacity' : 'highlight'}
          prefetch={false}
        >
          for talent
        </CTAButton>
        <CTAButton
          href="/for-companies"
          onMouseEnter={onCompanyAnimation}
          onFocus={onCompanyAnimation}
          onMouseLeave={onRemoveAnimation}
          onBlur={onRemoveAnimation}
          variant={currentAnimation === ANIMATIONS.TALENT ? 'opacity' : 'highlight'}
          className={styles['button']}
          prefetch={false}
        >
          for companies
        </CTAButton>
        <CTAButton
          href="/for-talent"
          className={classnames(styles['talent-button'], styles['mobile-button'])}
          variant="highlight"
          size="medium"
          prefetch={false}
        >
          for talent
        </CTAButton>
        <CTAButton
          href="/for-companies"
          variant="highlight"
          className={styles['mobile-button']}
          size="medium"
          prefetch={false}
        >
          for companies
        </CTAButton>
      </div>
      <div className={styles['background-lines-container']}>
        <GraphicLines className={classnames(styles['icon'], styles['background-lines-left'])} />
        <GraphicLinesTwo className={classnames(styles['icon'], styles['background-lines-right'])} />
      </div>
    </section>
  )
})
