import { NextSeo } from 'next-seo'
import type { NextSeoProps } from 'next-seo'
import { useRouter } from 'next/router'

type WebsiteSeoProps = {
  seo: NextSeoProps
}

export const WebsiteSeo = ({ seo }: WebsiteSeoProps) => {
  const websiteUrl = process.env.NEXT_PUBLIC_WEBSITE_URL
  const { asPath } = useRouter()
  const asPathWithoutParams = asPath.split('?')[0]
  const canonical = websiteUrl + asPathWithoutParams

  const websiteSeo = { canonical, ...seo }
  return <NextSeo {...websiteSeo} />
}

export default WebsiteSeo
