import {
  WebsiteLayout,
  Brands,
  HomeAnimatedSection,
  useCleanHeader,
  useUpdateHeader,
  HEADER_THEMES,
  WebsiteSeo,
} from '@platform/website-components'
import { ReactElement, useRef } from 'react'

import styles from './index.module.scss'

const SEO = {
  title: 'Olby // Engaging tech talent for trustworthy companies',
  description:
    'Olby reduces intercultural barriers and promotes thriving work relationships. We foster connections between Brazilian talent and international companies, boosting global tech careers.',
}

export const Landing = () => {
  const homeAnimationRef = useRef<HTMLElement | null>(null)
  const brandsRef = useRef<HTMLElement | null>(null)

  useUpdateHeader(homeAnimationRef, HEADER_THEMES.BLURRED)
  useUpdateHeader(brandsRef, HEADER_THEMES.EXT_DEEP_BLUE)

  useCleanHeader()
  return (
    <div className={styles['main']}>
      <WebsiteSeo seo={SEO} />
      <HomeAnimatedSection ref={homeAnimationRef} />
      <Brands className={styles['brands-section']} ref={brandsRef} />
    </div>
  )
}

export default Landing

Landing.getLayout = (page: ReactElement) => {
  return (
    <WebsiteLayout shouldChangeBgColor headerInitialTheme={HEADER_THEMES.BLURRED}>
      {page}
    </WebsiteLayout>
  )
}
