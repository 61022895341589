import classnames from 'classnames'
import { forwardRef } from 'react'

import { BRANDS_INFO } from '../../../utils/constants'

import styles from './index.module.scss'

type BrandsProps = {
  className?: string
}

const BRANDS_LIST = [
  BRANDS_INFO['loadsmart'],
  BRANDS_INFO['benepass'],
  BRANDS_INFO['hapana'],
  BRANDS_INFO['gbe'],
  BRANDS_INFO['scholarsOfFinance'],
  BRANDS_INFO['highnote'],
]

export const Brands = forwardRef<HTMLElement, BrandsProps>(({ className }, ref) => {
  return (
    <section className={classnames(styles['section-wrapper'], className)} ref={ref}>
      <div className={styles['content']}>
        <p className={styles['label']}>Trusted by</p>
        <div className={styles['brands-container']}>
          {BRANDS_LIST.map(brand => (
            <a
              href={brand.href}
              className={styles['link']}
              aria-label={`Access ${brand.id} homepage`}
              target="_blank"
              rel="noreferrer"
            >
              <brand.icon key={brand.id} className={styles['brand-icon']} />
            </a>
          ))}
        </div>
      </div>
    </section>
  )
})

export default Brands
